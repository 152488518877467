<template>
  <q-form ref="editForm">
    <!--상세-->
    <c-card class="cardClassDetailForm" title="LBLDETAIL">
      <template slot="card-button">
        <q-btn-group outline >
          <!--삭제-->
          <c-btn v-show="editable&&!disabled&&isOld" label="LBLREMOVE" icon="delete" @btnClicked="deleteLawCheck" />
          <!--저장-->
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="lawCheck"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveLawCheck"
            @btnCallback="saveLawCheckCallback" />
          <!--검토요청-->  
          <c-btn 
            v-show="requestBtnEditable" 
            :isSubmit="isRequest"
            :url="completeUrl"
            :param="lawCheck"
            mappingType="PUT"
            label="LBL0010088" 
            icon="check"
            @beforeAction="requestLawCheck"
            @btnCallback="requestLawCheckCallback" />
          <!--완료-->
          <c-btn 
            v-show="completeBtnEditable" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="lawCheck"
            mappingType="PUT"
            label="LBLCOMPLETE" 
            icon="check"
            @beforeAction="completeLawCheck"
            @btnCallback="completeLawCheckCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <!--담당자-->
          <c-field
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :data="lawCheck"
            deptValue="managerDeptCd"
            type="dept_user"
            label="LBLMANAGER"
            name="managerId"
            v-model="lawCheck.managerId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <!--적용부서-->
          <c-dept-multi 
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            :parentCheckDepts="lawCheck.acceptDeptCds" 
            label="LBL0010078" 
            name="acceptDeptCds" 
            v-model="lawCheck.acceptDeptCds" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <!--해당시설-->
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="LBL0010089"
            name="applicableFacility"
            v-model="lawCheck.applicableFacility">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <!--현행 법규내용 요약-->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="LBL0010090"
            name="currentLawContents"
            v-model="lawCheck.currentLawContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <!--개정 법규내용 요약-->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="LBL0010091"
            name="reLawContents"
            v-model="lawCheck.reLawContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <!--검토요청내용-->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="LBL0010092"
            name="reviewRequestContents"
            v-model="lawCheck.reviewRequestContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <!--비고-->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="LBLREMARK"
            name="remark"
            v-model="lawCheck.remark">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-card class="cardClassDetailForm q-mt-sm q-mb-sm" noHeader>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <!--법령명(약칭)-->
              <c-text
                :required="true" 
                :readonly="true"
                :editable="editable"
                :appendIcon="[{ name: 'search', click: true, callbackName: 'searchLaw', }]"
                label="LBL0010093"
                name="lawNameKor"
                v-model="lawCheck.lawNameKor"
                @searchLaw="searchLaw">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <!--공포일자-->
              <c-text
                :editable="editable"
                :readonly="true"
                label="LBL0010081"
                name="promulgationDate"
                v-model="lawCheck.promulgationDate">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <!--제개정-->
              <c-text
                :editable="editable"
                :readonly="true"
                label="LBL0010082"
                name="revisiontypename"
                v-model="lawCheck.revisiontypename">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <!--소관부처-->
              <c-text
                :editable="editable"
                :readonly="true"
                label="LBL0010083"
                name="competentMinistriesName"
                v-model="lawCheck.competentMinistriesName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <!--법령구분-->
              <c-text
                :editable="editable"
                :readonly="true"
                label="LBL0010084"
                name="lawClassName"
                v-model="lawCheck.lawClassName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <!--시행일자-->
              <c-text
                :editable="editable"
                :readonly="true"
                label="LBL0010085"
                name="enforcementDate"
                v-model="lawCheck.enforcementDate">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <q-chip 
                v-if="lawCheck.mdmLawId" 
                outline square
                color="teal" text-color="white" icon="open_in_new" 
                clickable
                class="cursor-pointer" 
                @click="fileDown">법령안</q-chip>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <!--검토자 목록-->
    <c-table
      ref="table"
      title="LBL0010094"
      :columns="userTableColumns"
      :data="lawCheck.checkUsers"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="mdmLawCheckUserId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--제외-->
          <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="remove" @btnClicked="deleteChecker" />
          <!--추가-->
          <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addChecker" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'law-review-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmLawCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      lawCheck: {
        /** 입법예고검토 정보 */
        mdmLawCheckId: '',  // 법령검토 번호
        lawId: '',  // 법령ID
        mdmLawId: '',  // 시행일 법령 번호
        mdmLawStepCd: '',  // 진행단계
        managerId: '',  // 담당자id
        acceptDeptCds: '',  // 적용부서
        currentLawContents: '',  // 현행 법규내용 요약
        reLawContents: '',  // 개정 법규내용 요약
        applicableFacility: '',  // 해당시설
        reviewRequestContents: '',  // 검토요청내용
        remark: '',  // 비고
        /** 입법예고 정보 */
        lawSerialNum: '',  // 법령일련번호
        curHistroyCode: '',  // 현행연혁코드
        lawNameKor: '',  // 법령명한글
        lawNameAbb: '',  // 법령약칭명
        promulgationDate: '',  // 공포일자
        promulgationNum: '',  // 공포번호
        revisiontypename: '',  // 제개정구분명
        competentMinistriesCode: '',  // 소관부처코드
        competentMinistriesName: '',  // 소관부처명
        lawClassName: '',  // 법령구분명
        enforcementDate: '',  // 시행일자
        lawTypeFlag: '',  // 자법타법여부
        lawLink: '',  // 법령상세링크
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checkUsers: [], // 검토자들
        deleteCheckUsers: [], // 검토자들 [삭제]
      },
      attachInfo: {
        isSubmit: '', // 판정에서 데이터가 저장되어 키가 있는 상황임으로 사용하지 않음
        taskClassCd: 'LAW_CHECK',
        taskKey: '',
      },
      popupOptions: {
        isFull: false,
        width: '65%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      isSave: true,
      isRequest: true,
      isComplete: true,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmLawCheckId)
    },
    disabled() {
      return this.lawCheck.mdmLawStepCd === 'LS00000010'
    },
    requestBtnEditable() {
      return this.editable && !this.disabled && this.lawCheck.mdmLawStepCd === 'LS00000001' && this.isOld
    },
    completeBtnEditable() {
      return this.editable && !this.disabled && this.lawCheck.mdmLawStepCd === 'LS00000005' && this.isOld
    },
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 550) + 'px' : '500px'
    },
    userTableColumns() {
      let _item = [
        {
          name: 'checkDeptName',
          field: 'checkDeptName',
          //부서
          label: 'LBLDEPT',
          align: 'center',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'checkUserName',
          field: 'checkUserName',
          //검토자
          label: 'LBLREVIEWER',
          align: 'center',
          style: 'width:100px',
          sortable: true,
        },
      ];
      if (this.isOld && this.lawCheck.mdmLawStepCd !== 'LS00000001') {
        _item.push({
          name: 'remark',
          field: 'remark',
          //검토의견
          label: 'LBL0010095',
          align: 'left',
          style: 'width:400px',
          sortable: true,
          type: 'textarea'
        })
      }
      return _item
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.lawCheck.check.get.url
      this.saveUrl = transactionConfig.sai.lawCheck.check.insert.url
      this.deleteUrl = transactionConfig.sai.lawCheck.check.delete.url
      this.completeUrl = transactionConfig.sai.lawCheck.check.complete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmLawCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmLawCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo',_result.data)

          this.$_.extend(this.lawCheck, _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.mdmLawCheckId)
        },);
      } else {
        this.$set(this.lawCheck, 'mdmLawStepCd', 'LS00000001')
        this.$set(this.lawCheck, 'managerId', this.$store.getters.user.userId)
        this.$set(this.lawCheck, 'managerDeptCd', this.$store.getters.user.deptCd)
      }
    },
    searchLaw(icon) {
      if (icon) {
        this.popupOptions.title = 'LBL0010087';   //법령 검토
        this.popupOptions.param = {
          type: 'single',
        };
        this.popupOptions.target = () => import(`${'@/pages/chm/law/lawPop.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.width = '70%';
        this.popupOptions.closeCallback = this.closeLawPopup;
      }
    },
    closeLawPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.extend(this.lawCheck, data[0])
      }
    },
    fileDown() {
      window.open('http://www.law.go.kr' + this.lawCheck.lawLink, '법규', 'width=1200, height=700');
    },
    addChecker() {
      this.popupOptions.title = 'LBL0000560'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.lawCheck.checkUsers, { checkUserId: item.userId }) === -1) {
            _data.push({
              mdmLawCheckUserId: uid(),  // 법령검토자 번호
              mdmLawCheckId: this.popupParam.mdmLawId,  // 법령검토 번호
              checkDeptCd: item.deptCd, checkDeptName: item.deptName, 
              checkUserId: item.userId, checkUserName: item.userName,
              remark: '',
              finishFlag: 'N',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        this.lawCheck.checkUsers = this.$_.concat(this.lawCheck.checkUsers, _data)
      }
    },
    deleteChecker() {
      let selectData = this.$refs[`table`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.lawCheck.deleteCheckUsers) this.lawCheck.deleteCheckUsers = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.lawCheck.deleteCheckUsers, { mdmLawCheckUserId: item.mdmLawCheckUserId })) {
                  this.lawCheck.deleteCheckUsers.push(item)
              }
              this.lawCheck.checkUsers = this.$_.reject(this.lawCheck.checkUsers, item);
            })
            this.$refs[`table`].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveLawCheck() {
      if (this.popupParam.mdmLawCheckId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.lawCheck.check.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.lawCheck.check.insert.url
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.lawCheck.regUserId = this.$store.getters.user.userId
              this.lawCheck.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveLawCheckCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'mdmLawCheckId', _result.data)
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    requestLawCheck() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',   // 확인
            message: 'MSG0010000', // 검토요청하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.lawCheck.chgUserId = this.$store.getters.user.userId

              this.isRequest = !this.isRequest
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    requestLawCheckCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeLawCheck() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',    // 확인
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.lawCheck.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeLawCheckCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteLawCheck() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.mdmLawCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>